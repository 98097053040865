import { APIResponse, CastawayDetails } from "../../../@types";

const URL = import.meta.env.VITE_API_URL;

export async function fetchCastawayDetails() {
  const requestOptions: RequestInit = {
    method: "GET",
    redirect: "follow",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
    },
  };
  const result = (await (
    await fetch(URL + `/seasons/48/castaways`, requestOptions)
  ).json()) as APIResponse;
  if (result.success) return result.data as CastawayDetails[];

  return result.success;
}
