import styled from "styled-components";
import { PageHeader } from "../components/visuals/PageHeader";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from "react-query";
import { createNewTribe, fetchPlayersTribes } from "../api/tribes";
import { Loading } from "../components/elements/Loading";
import { TribesData } from "../../../@types";
import { useMemo, useState } from "react";
import { Panel } from "../components/visuals/Panel";
import PersonHeadIcon from "../assets/icons/person-line-drawing-svgrepo-com.svg";
import { useNavigate } from "react-router-dom";
import { Modal } from "../components/elements/Modal";
import { TextInput } from "../components/elements/TextInput";
import { Select } from "../components/elements/Select";
import { fetchPlayableSeasons } from "../api/seasons";
import { Button } from "../components/elements/Button";
import { Circle } from "../components/visuals/Circle";
import { Icon } from "../components/visuals/GameIcons";

export function MyTribesPage() {
  const {
    data,
    isLoading: tribeDataLoading,
    refetch,
  } = useQuery("tribes", () => fetchPlayersTribes());
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const sortedData = useMemo(() => sortData(data || []), [data]);
  if (data === undefined || tribeDataLoading) return <LoadingPage />;

  return (
    <Layout>
      <PageHeader title="My Tribes" />
      <TribesList data={sortedData} />
      <Circle color="#23CE6B" onClick={() => setIsModalOpen(true)}>
        <Icon name="add" size={`40px`} />
      </Circle>
      <AddTribeModal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        refetch={refetch}
      />
    </Layout>
  );
}

type AddTribeModalProps = {
  isOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<TribesData[], unknown>>;
};
function AddTribeModal({
  isOpen,
  setIsModalOpen,
  refetch,
}: AddTribeModalProps) {
  const { data: seasons, isLoading: seasonDataLoading } = useQuery(
    "seasons",
    () => fetchPlayableSeasons()
  );
  const [tribeName, setTribeName] = useState<string | undefined>();

  if (seasons === undefined || seasonDataLoading) return <Loading />;

  async function handleCreateClick() {
    if (tribeName !== undefined) {
      setIsModalOpen(false);
      await createNewTribe(tribeName, 48);
      refetch();
    }
  }

  return (
    <Modal open={isOpen} onClose={() => setIsModalOpen(false)}>
      <ModalLayout>
        Create A Tribe
        <TextInput
          placeholder="Tribe Name"
          onChange={(e) => setTribeName(e.target.value)}
        />
        <Button
          disabled={tribeName === undefined || tribeName === ""}
          onClick={handleCreateClick}
        >
          Create
        </Button>
      </ModalLayout>
    </Modal>
  );
}

function TribesList({ data }: { data: { [key: number]: TribesData[] } }) {
  const navigate = useNavigate();
  return (
    <TribesContainer>
      {Object.entries(data)
        .sort((a, b) => Number(b[0]) - Number(a[0]))
        .map((v) => {
          return (
            <SeasonContainer key={v[0]}>
              <SeasonTitle>Season {v[0]}</SeasonTitle>
              {v[1].map((tribe) => {
                return (
                  <TribeContainer
                    key={tribe.tribe_id}
                    onClick={() => navigate(`/tribe/${tribe.tribe_id}`)}
                  >
                    <TribeName>{tribe.tribe_name}</TribeName>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "8px",
                      }}
                    >
                      <img
                        src={PersonHeadIcon}
                        width={"35px"}
                        style={{ margin: "0" }}
                        alt="Head outline"
                      />
                      <PlayerCount>{tribe.player_count}</PlayerCount>
                    </div>
                  </TribeContainer>
                );
              })}
            </SeasonContainer>
          );
        })}
    </TribesContainer>
  );
}

function LoadingPage() {
  return (
    <>
      <PageHeader title="My Tribes" />
      <Loading />
    </>
  );
}

function sortData(data: TribesData[]) {
  return data.reduce((result, item) => {
    const { season_id } = item;
    if (!result[season_id]) {
      result[season_id] = [];
    }

    result[season_id].push({ ...item });
    return result;
  }, {}) as { [key: number]: TribesData[] };
}

const Layout = styled.div`
  display: grid;
`;

const SeasonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 90%;
  margin: auto;
  gap: 10px;
`;
const SeasonTitle = styled.h1`
  font-size: 20px;
  margin-bottom: 5px;
`;
const TribeContainer = styled(Panel)`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  padding: 10px 20px;

  :hover {
    cursor: pointer;
  }
`;
const TribesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
`;
const PlayerCount = styled.div`
  font-size: 35px;
  font-weight: 100;
`;
const TribeName = styled.div`
  font-size: 20px;
  font-weight: 100;
`;
const ModalLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const CreateTribeButton = styled.button`
  border-radius: 50%;
  background-color: #23ce6b;
  width: 50px;
  height: 50px;
  border: none;
  position: absolute;
  right: 5%;
  bottom: 3%;
  font-size: 45px;
  color: white;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
`;
