import styled from "styled-components";
import { StyledLink } from "../components/elements/StyledLink";
import { PageHeader } from "../components/visuals/PageHeader";
import { Button } from "../components/elements/Button";
import { Link, useNavigate } from "react-router-dom";

export function HomePage() {
  const navigate = useNavigate();
  return (
    <>
      <PageHeader title="Tribal Triumph" />
      <Layout>
        <img
          style={{ marginTop: "1rem", width: "100%", maxWidth: "800px" }}
          src="../casts/48.png"
          alt="Survivor 48 cast photo"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SubTitle>Welcome back</SubTitle>
          <Text>
            If this is your first time here, checkout the rules below then head
            to your tribes.
          </Text>
          <ButtonLink to="/tribes">Go to my tribes</ButtonLink>
          <SubTitle>Rules</SubTitle>
          <Text>
            Think you know who will be the sole survivor? Compete against a
            "tribe" of your friends to put your guessing skills to the test.
          </Text>
          <Text>
            Make your picks before the start of each episode on Wednesday
          </Text>
          <Text>
            For each of your tribes, select who you think will gain an
            advantage, get voted out, etc. Points will be awarded for correct
            predictions.
          </Text>
          <Text>
            For predictions made before the merge, points will be awarded for
            picking any castaway on the winning tribe for immunity & reward
            challenges.
          </Text>
          <Text>
            The number of points awarded for each event are not yet final. The
            number of points awarded may change to better balance the game.
          </Text>
        </div>
      </Layout>
    </>
  );
}

const Layout = styled.div`
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;

  @media only screen and (min-width: 1200px) {
    grid-template-columns: auto 1fr;
    gap: 1rem;
    align-items: start;
  }
`;
const SubTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 700;
  margin-top: 2rem;
  text-align: center;

  @media only screen and (min-width: 1200px) {
    text-align: left;
  }
`;
const Text = styled.p`
  text-align: center;
  grid-column: 1 / -1;
  margin: 1rem;

  @media only screen and (min-width: 800px) {
    text-align: left;
  }
`;
const ButtonLink = styled(StyledLink)`
  display: inline-block;
  text-align: center;
  border-radius: ${(props) => props.theme.format.borderradius};
  color: white;
  font-size: 1.3rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
  background-color: #235cce;
  border: none;
  padding: 1rem;
  width: 80%;
  margin-top: 2rem;
`;
